import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react'
import bepassCopyright from './images/bepass_logo_default.png'
import bepassCopyrightPb from './images/bepass_logo_pb.png'
import bepassLogo from './images/bepass_logo.png'
import clientLogo from './images/client_logo.png'
import './App.sass'

import { BASE } from './basedata/constants'

import { getAuth, signInAnonymously } from "firebase/auth";


import FaceOnboard from './Biometric/FaceOnboard'
import FaceInstructions from './Biometric/FaceInstructions'
import UserSignup from './User/UserSignup'
import Welcome from './Welcome'
import SuccessHandler from './Biometric/StatesComponents/SuccessHandler'
import { Card } from 'react-bootstrap'

import { createGlobalStyle } from 'styled-components';
import DocumentInstructions from './Biometric/DocumentInstructions'
import DocumentOnboard from './Biometric/DocumentOnboard'
import DocumentTypeErrorHandler from './Biometric/StatesComponents/DocumentTypeHandler'
import PersonErrorHandler from './Biometric/StatesComponents/PersonErrorHandler'
import LoadingHandler from './Biometric/StatesComponents/LoadingHandler'
import UserDocumentCheck from './User/UserDocumentCheck'
import UserDocumentCheckFail from './User/UserDocumentCheckFail'
import LoadingHandlerValidator from './Biometric/StatesComponents/LoadingHandlerValidator'
import DocumentPassportMessage from './Biometric/StatesComponents/DocumentPassportMessage'

import { fetchWithApiKey } from './basedata/apiCall'
import { auth } from './basedata/firebase'

function App() {
  const [userState, setuserState] = useState<string>('')
  const [userToken, setuserToken] = useState<any>('')

  const [tempCredentials, setTempCredentials] = useState<any>('')

  const [loading, setloading] = useState(false)

  const [docType, setdocType] = useState('')

  const [primaryColor, setPrimaryColor] = useState(BASE.company.primaryColor);
  const [secondaryColor, setSecondaryColor] = useState(BASE.company.secondaryColor);
  const [tertiaryColor, setTertiaryColor] = useState(BASE.company.tertiaryColor);
  const [demoLogo, setDemoLogo] = useState('');
  const [copyrightLogo, setCopyrightLogo] = useState(BASE.company.copyrightLogo);
  const [backgroundColor, setBackgroundColor] = useState(BASE.company.backgroundColor);

  const handleResponse = useCallback((message: any) => {

    if(message.docType){
      setdocType(message.docType)
    }
    setuserState(message.step)
    if (message.token) {
      setuserToken(message.token)
    }
  }, [])

  const verifyBiometry = (token: any, orc: any) => {


    fetchWithApiKey(`${BASE.user_biometry.base_url}${BASE.user_biometry.document_status}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //Authorization: BASE.api.token
      },
      body: JSON.stringify({
        "token": token,
        "session": orc
      })
    })
      .then((resg) => resg.json())
      .then((response) => {

        setloading(false)

        if (response.message === 'success') {

          if (response.userInformation.callbackURL) {
            window.sessionStorage.setItem('callbackurl', `${response.userInformation.callbackURL}`)
          }
          return setuserState('facesuccess')

        } else if(response.message === 'documentNotFound') {
          return setuserState('documentinformation')
        } else if(response.message === 'notFound') {
          return setuserState('faceinformation')
        } else if(response.message === 'pending') {
          return setuserState('loadingCheck')
        }else if(response.message === 'errorDoc'){
          return setuserState('invalidDoc')
        }else if(response.message === 'errorPerson'){
          return setuserState('analysis')
        }else if(response.message === 'hasSelfie'){
          return setuserState('documentinformation')
        }else if(response.message === 'passportValidation'){
          return setuserState('documentinformation')
        }

        const params = new URLSearchParams(window.location.search)
        if(params.get('doc') === 'true') {
          return setuserState('documentinformation')
        }
  
        return setuserState('welcome')

      })
      .catch((error) => {
        setloading(false)

        window.gtag('set', {
          'onboarding_error': JSON.stringify(error),
        });

        return false
      })
  }

  const getOnboardingData = () => {

    setloading(true)

    const slug = window.location.pathname.split('/')[1]

    if (!slug) {
      setloading(false)
      return false
    }

    fetchWithApiKey(`${BASE.dashboard_api.base_url}${BASE.dashboard_api.get_company_onboarding}/${slug}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        //Authorization: BASE.api.token
      }
    })
      .then((resg) => resg.json())
      .then((response) => {

        setPrimaryColor(response.main_color)
        setSecondaryColor(response.secondary_color)
        setTertiaryColor(response.tertiary_color)
        setDemoLogo(response.logo)
        setCopyrightLogo(response.bepass_logo)
        setBackgroundColor(JSON.parse(response.background_color))

        if (response.default_step === 'face') {
          setuserToken('xxxxxxx')
          window.sessionStorage.setItem('token', 'xxxxxxx')
          setloading(false)
          return setuserState('welcome')
        } else {
          window.sessionStorage.removeItem('token')
        }

        setloading(false)

      })
      .catch((error) => {
        setloading(false)
        return false
      })
  }

const getCredentials = async () => {

  try{
    const userCredential:any = await signInAnonymously(auth)

    sessionStorage.setItem('user-key', JSON.stringify({user_key: userCredential?.user?.accessToken, tenant_id: BASE.company.tenant}))
    const sessionItem:any = window.sessionStorage.getItem('user-key')
  
    setTempCredentials(userCredential.user?.accessToken)
  }catch(e){
    return false
  }
  return true
}

  const initialized = useRef(false)
  useEffect(() => {

    if (!initialized.current) {
    initialized.current = true

    getCredentials();

  }
  }, [])

  
  const initializedPlus = useRef(false)
  useEffect(() => {

    if (!initializedPlus.current) {
    initializedPlus.current = true

    setloading(true)

    getOnboardingData()
    const params = new URLSearchParams(window.location.search)
    const generatedToken = window.sessionStorage.getItem('token')
    const generatedCompany = window.sessionStorage.getItem('orc')

    if (params.get('token')) {

      const currentToken = params.get('token'),
      currentCompany = params.get('orc')
      setuserToken(params.get('token'))
      window.sessionStorage.setItem('token', currentToken + '')
      window.sessionStorage.setItem('originCompany', currentCompany + '')


      const documentCheck = window.sessionStorage.getItem('doccheck')
      if(!documentCheck){
        setloading(false)
        return setuserState('documentCheck')
      }

      return verifyBiometry(currentToken, currentCompany)

    }

    else if (generatedToken) {

      setuserToken(generatedToken)

      const documentCheck = window.sessionStorage.getItem('doccheck')
      if(!documentCheck){
        setloading(false)
        return setuserState('documentCheck')
      }

      return verifyBiometry(generatedToken, generatedCompany)

    }
    return setuserState('welcome')
  }
  }, [tempCredentials])


  const GlobalStyle = createGlobalStyle`
  body {
    background: linear-gradient(0deg, ${backgroundColor[0]} 0%, ${backgroundColor[1]} 100%) no-repeat
  }
`;

  if (loading) {
    return (<></>)
  }


  return (
    <>
      <GlobalStyle />
      <div style={{ '--pm-dark': secondaryColor, '--pm-primary': primaryColor } as CSSProperties} className="Container">
        <div className="logos">
          <img src={demoLogo ? demoLogo : BASE.company.logo} className="logo" alt="BePass" />
        </div>
        {userState === 'documentinformation' ? (
          <DocumentInstructions onResponse={handleResponse} />
        ) : userState === 'documentproceed' ? (
          <DocumentOnboard  token={userToken} choosenDocType={docType} onResponse={handleResponse} />
        ) : userState === 'faceinformation' ? (
          <FaceInstructions onResponse={handleResponse} />
        ) : userState === 'facelogin' ? (
          <FaceOnboard onResponse={handleResponse} token={userToken} />
        ) : userState === 'faceproceed' ? (
          <FaceOnboard onResponse={handleResponse} token={userToken} />
        ) : userState === 'signup' ? (
          <UserSignup onResponse={handleResponse} />
        ) : userState === 'facesuccess' ? (
          <Card>
            <SuccessHandler />
          </Card>
        ) : userState === 'invalidDoc' ? (
          <div className={`card proccess-card documentContainer`}>
            <DocumentTypeErrorHandler onResponse={handleResponse} />
          </div>
        ) : userState === 'analysis' ? (
          <div className={`card proccess-card documentContainer`}>
            <PersonErrorHandler onResponse={handleResponse} />
          </div>
        ) : userState === 'loading' ? (
          <div className={`card proccess-card documentContainer`}>
            <LoadingHandler/>
          </div>
        ) : userState === 'loadingCheck' ? (
          <div className={`card proccess-card documentContainer`}>
            <LoadingHandlerValidator/>
          </div>
        ) : userState === 'documentCheck' ? (
            <UserDocumentCheck onResponse={handleResponse} />
        )  : userState === 'documentCheckFail' ? (
          <UserDocumentCheckFail onResponse={handleResponse} />
        ): userState === 'welcome' ? (
          <Welcome onResponse={handleResponse} />
        ): userState === 'passportValidation' ? (
          <div className={`card proccess-card documentContainer`}>
            <DocumentPassportMessage onResponse={handleResponse} />
          </div>
        ) : <></>}
      </div>
      <p className="copyright">
        <span>v12.4</span> powered by{' '}
        <a href="http://bepass.com.br" rel="noreferrer" target="_blank">
          <img src={copyrightLogo === 'white' ? bepassCopyrightPb : bepassCopyright} className="logo-bepass" alt="bepass" />
        </a>
      </p>
    </>
  )
}

export default App
